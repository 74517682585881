<template>
  <v-container>
    <v-app-bar dark app color="primary">
      <v-app-bar-nav-icon>
        <v-icon>mdi-account-multiple</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title>temporäre Lehrer*innen</v-toolbar-title>
      <v-spacer />
      <SearchField v-model="search" hint="Name oder Kürzel"></SearchField>
    </v-app-bar>
    <v-card>
      <v-data-table
        :loading="loading"
        :headers="headers"
        :items="items"
        :search="search"
        hide-default-footer
        :sort-by="'startDate'"
        :custom-filter="filter"
        :items-per-page="-1"
      >
        <template v-slot:item.temporary="{ item }">
          <PersonItem clickable :value="item.temporary" />
        </template>
        <template v-slot:item.startDate="{ item }">
          <DateValue :value="item.startDate" />
        </template>
        <template v-slot:item.endDate="{ item }">
          <DateValue :value="item.endDate" />
        </template>
      </v-data-table>
    </v-card>
  </v-container>
</template>
<script>
import { comparePeople, searchPerson } from "common/utils/people.js";
import DateValue from "common/components/DateValue.vue";
import PersonItem from "@/components/PersonItem.vue";
import SearchField from "@/components/SearchField.vue";

export default {
  name: "Absences",
  components: {
    DateValue,
    PersonItem,
    SearchField,
  },
  data() {
    return {
      loading: false,
      search: "",
      headers: [
        { text: "Lehrer*in", value: "temporary", sort: comparePeople },
        { text: "von", value: "startDate" },
        { text: "bis", value: "endDate" },
        { text: "Schlüssel", value: "legicUid" },
        { text: "Typ", value: "type" },
      ],
      items: [],
    };
  },
  created() {
    this.getData();
  },
  methods: {
    async getData() {
      this.loading = true;
      this.items = await this.apiList({ resource: "teacher/temporary" });
      this.loading = false;
    },
    filter(value, search) {
      return (
        value != null &&
        search != null &&
        typeof value === "object" &&
        searchPerson(value, search)
      );
    },
  },
};
</script>
