<template>
  <v-text-field
    v-model="modelValue"
    append-icon="mdi-magnify"
    :label="label"
    :hint="hint"
    single-line
    hide-details
    persistent-hint
    clearable
    id="search"
  ></v-text-field>
</template>
<script>
export default {
  props: {
    hint: { type: String, default: null },
    label: { type: String, default: 'Suche' },
    value: { type: String, default: null },
  },
  data() {
    return {
      modelValue: this.value,
    };
  },
  watch: {
    modelValue() {
      this.$emit('input', this.modelValue);
    },
    value() {
      this.modelValue = this.value;
    },
  },
};
</script>
